import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  root: {
    minWidth: 'auto',
  },
  text: ({ align }) => ({
    justifyContent: ({
      left: 'flex-start',
    })[align],
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
})

const TextButton = (props) => {
  const classes = useStyles(props)

  return (
    <Button
      classes={classes}
      disableRipple
      variant="text"
      {...props}
    />
  )
}

export default TextButton
