/**
 * Retrieve the copy from the CMS based on its ID
 */
import React, { useState, useEffect } from 'react'
import contentful from 'utils/contentful'

const useAssets = (id) => {
  const [loading, setLoading] = useState(true)
  const [asset, setAsset] = useState(null)
  const [fileLink, setFileLink] = useState(null)

  const getAsset = async () => {
    setLoading(true)
    try {
      const res = await contentful.getAssets({
        'fields.title': id,
      })

      setFileLink(res.items[0]?.fields.file.url)
      setAsset(res.items[0])
    } catch (err) {
      console.log(err)
    }

    setLoading(false)
  }

  useEffect(() => {
    getAsset()
  }, [])

  return [fileLink, asset, loading]
}

export default useAssets
