import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import TextButton from 'components/TextButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HeaderText from 'components/HeaderText'

import Typography from 'components/Typography'
import contentful from 'utils/contentful'
import pdfIcon from 'assets/icons/pdf.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0px 0px 80px',
  },
}))

const useDocStyles = makeStyles((theme) => ({
  underline: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  pdfIcon: {
    width: 16,
    paddingTop: 5,
  },
  quarterWrapper: {
    margin: '32px 0px',
  },
  quarterLabel: {
    textAlign: 'left',
    '@media (max-width: 425px)': {
      fontSize: 18,
    },
  },
  documentLink: {
    textDecoration: 'underline',
    margin: '0 16px',
  },
}))


const InvestorDeck = () => {
  const [deckFile, setDeckFile] = useState(null)
  const classes = useDocStyles()

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'investorDeck',
        })

        const [deck] = res.items
        const { file } = deck.fields.file.fields

        setDeckFile(file)
      } catch (err) {
        console.log('Could fetch investor deck.', err)
      }
    }

    fetchFile()
  }, [])

  return (
    <div>
      <HeaderText variant="h4" color="secondary">Investor Deck</HeaderText>
      <div className={classes.quarterWrapper}>
        <Typography
          component="a"
          variant="h5"
          color="secondary"
          href={deckFile?.url}
          className={classes.quarterLabel}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pdfIcon} className={classes.pdfIcon} alt="Backstageplay Investor Deck" /> Download the Investor Deck.
        </Typography>
      </div>
    </div>
  )
}

const InvestorDeckWrapper = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <InvestorDeck />
    </div>
  )
}

InvestorDeckWrapper.propTypes = {}
InvestorDeckWrapper.defaultProps = {}

export default InvestorDeckWrapper
