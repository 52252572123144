/**
 * Retrieve the copy from the CMS based on its ID
 */
import React, { useState, useEffect } from 'react'
import contentful from 'utils/contentful'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Typography from '@material-ui/core/Typography'
import FileDownload from 'components/FileDownload'
import { HEADING_6, BODY, IMAGE } from '../Pages/Blog/components/BlogBlocks'

const documentFormatOptions = {
  // https://www.npmjs.com/package/@contentful/rich-text-react-renderer
  // replace \n with <br />
  renderText: (text) => {
    if (text === '') return [<br key={`${text}-key`} />]

    const result = text.split('\n').reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [])

    return result
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, file } = node?.data?.target?.fields

      if (!file.contentType.includes('image')) {
        return <FileDownload type={file.contentType} title={title} size={file.details.size} src={file.url} />
      }

      return <IMAGE src={file.url} alt={title} />
    },
    [BLOCKS.HEADING_1]: (node, children) => <Typography variant="h2">{children}</Typography>,
    [BLOCKS.HEADING_2]: (node, children) => <Typography variant="h3">{children}</Typography>,
    [BLOCKS.HEADING_3]: (node, children) => <Typography variant="h4">{children}</Typography>,
    [BLOCKS.HEADING_6]: (node, children) => <HEADING_6>{children}</HEADING_6>,
    [BLOCKS.PARAGRAPH]: (node, children) => <span>{children}</span>,
  },
}

const useSiteCopy = (id) => {
  const [loading, setLoading] = useState(true)
  const [copy, setCopy] = useState(null)
  const [rawCopyValues, setRawCopyValues] = useState([])

  const getSiteCopy = async () => {
    setLoading(true)
    try {
      const res = await contentful.getEntries({
        content_type: 'siteCopy',
        'fields.id': id,
      })

      const data = res.items[0]

      const payload = {
        id,
        sysId: data.sys.id,
        title: data.fields.title,
      }

      // // parse rich text into React components for easy render
      payload.body = documentToReactComponents(data.fields.copy, documentFormatOptions)

      try {
        const rawValues = data.fields.copy.content.reduce((acc, cur) => {
          const { content: [node] } = cur
          acc.push(node.value)

          return acc
        }, [])

        setRawCopyValues(rawValues)
      } catch (err) {
        console.log(err)
      }

      setCopy(payload)
    } catch (err) {
      console.log(err)
    }

    setLoading(false)
  }

  useEffect(() => {
    getSiteCopy()
  }, [])

  return [copy, loading, rawCopyValues]
}

export default useSiteCopy
