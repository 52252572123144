import React from 'react'
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'
import Header, { HEADER_HEIGHT } from 'components/Header/Header'
import Footer from 'components/Footer'
import ScrollToTop from 'components/ScrollToTop'
import SubscriptionPopup from 'components/SubscriptionPopup'
import { colors } from 'theme'

import useRoutes from './utils/useRoutes'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.background,
    position: 'relative',
  },
  footer: {
    width: '100%',
    margin: '0 auto',
    position: 'relative',
  },
  headerOffset: {
    marginTop: HEADER_HEIGHT.lg,
    [theme.breakpoints.down('sm')]: {
      marginTop: HEADER_HEIGHT.sm,
    },
  },
}))

const Routes = () => {
  const classes = useStyles()
  const routes = useRoutes()

  return (
    <Router>
      <div className={classes.root}>
        <Route component={ScrollToTop} />
        <Route component={Header} />
        <Switch>
          {
            routes.map((x) => (
              <Route
                key={x.link}
                path={x.link}
                exact={x.exact}
                render={(props) => {
                  if (!x.component) return <Redirect to="/" />

                  const matchesPath = props.location.pathname === x.link
                  const matchesNestedPath = props.location.pathname.includes(x.link)

                  return (
                    <Fade
                      in={matchesPath || matchesNestedPath}
                      timeout={500}
                      style={{ transitionDelay: '550ms' }}
                    >
                      <div className={classes.headerOffset}>
                        <x.component {...props} />
                      </div>
                    </Fade>
                  )
                }}
              />
            ))
          }
          <Redirect to="/" />
        </Switch>
      </div>
      <SubscriptionPopup />
      <Footer />
    </Router>
  )
}

export default Routes
