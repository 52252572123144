import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import TextButton from 'components/TextButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import HeaderText from 'components/HeaderText'

import Typography from 'components/Typography'
import contentful from 'utils/contentful'
import pdfIcon from 'assets/icons/pdf.svg'
import { assertTSBigIntKeyword } from '../../../../../../Library/Caches/typescript/4.3/node_modules/@babel/types/lib/index'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0px 0px',
  },
}))

const useDocStyles = makeStyles((theme) => ({
  underline: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  pdfIcon: {
    width: 16,
    paddingTop: 5,
  },
  quarterWrapper: {
    margin: '32px 0px',
  },
  quarterInfo: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0',
    '@media (max-width: 425px)': {
      alignItems: 'flex-start',
    },
  },
  quarterLabel: {
    width: 120,
    textAlign: 'left',
  },
  documentLink: {
    textDecoration: 'underline',
  },
}))


const FinancialDocuments = () => {
  const [assets, setAssets] = useState(null)
  const classes = useDocStyles()
  const matchesXs = useMediaQuery('@media (max-width: 425px)')

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'financialResults',
        })
        const [deck] = res.items
        const { file } = deck.fields
        
        // const res = await contentful.getEntries({
        //   content_type: 'quarterlyFinancialResults',
        // })
        // // group into years
        // const groupedByYears = res.items.reduce((acc, { fields }) => {
        //   if (!acc[fields.year]) {
        //     return { ...acc, [fields.year]: [fields] }
        //   }

        //   return {
        //     ...acc,
        //     [fields.year]: [...acc[fields.year], fields],
        //   }
        // }, {})
        setAssets(file)
      } catch (err) {
        console.log('Could not sort documents.', err)
      }
    }

    fetchAssets()
  }, [])

  return (
    <div>
      <HeaderText variant="h4" color="secondary">2023 AGM Materials</HeaderText>
      <div>
        {
          assets
            ? (
              <div>
                {
                  assets.map((x, i) => (
                    <div className={classes.quarterWrapper}>
                      <Typography
                        component="a"
                        variant="h5"
                        color="secondary"
                        href={x.fields.file.url}
                        className={classes.quarterLabel}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={pdfIcon} className={classes.pdfIcon} alt={`${x.fields.title}`} /> {i+1}. {x.fields.title}
                      </Typography>
                    </div>
                  ))
                }
              </div>
            )
            //   Object.keys(assets).map((year) => (
            //     <div key={year} className={classes.quarterWrapper}>
            //       <Typography color="secondary" variant="h5" gutterBottom>{year}</Typography>
            //       <Grid container direction="column" spacing={2}>
            //         {
            //           assets[year].sort((a, b) => a.quarter - b.quarter).map(({ quarter, financial, mda }) => (
            //             <Grid key={`${year}-${quarter}`} item container alignItems="center" xs={12} spacing={matchesXs ? 1 : 3}>
            //               <Grid item xs={12} sm="auto">
            //                 <Typography color="secondary">Quarter {quarter}</Typography>
            //               </Grid>
            //               <Grid item xs={12} sm="auto">
            //                 <Typography component="a" color="secondary" href={financial.fields.file.url} className={classes.documentLink} target="_blank" rel="noopener noreferrer"><img src={pdfIcon} className={classes.pdfIcon} alt="Financial" /> Financial Statements</Typography>
            //               </Grid>
            //               <Grid item xs={12} sm="auto">
            //                 <Typography component="a" color="secondary" href={mda.fields.file.url} className={classes.documentLink} target="_blank" rel="noopener noreferrer"><img src={pdfIcon} className={classes.pdfIcon} alt="MDA PDF Icon" /> Management Discussion and Analysis</Typography>
            //               </Grid>
            //             </Grid>
            //           ))
            //         }
            //       </Grid>
            //     </div>
            //   ))
            // )
            : null
        }
      </div>
    </div>
  )
}

const FinancialResults = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <FinancialDocuments />
    </div>
  )
}

FinancialResults.propTypes = {}
FinancialResults.defaultProps = {}

export default FinancialResults
