import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles, styled } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useAssets from 'utils/useAssets'
import {
  TEAM, ADVISORS, INVESTORS, NEWSROOM,
} from 'utils/useRoutes'
import { colors } from 'theme'

const Logo = styled('img')({
  width: 250,
})

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 'calc(((100vw - 1080px) / 2) + 60px)',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '@media (max-width: 425px)': {
      display: 'none',
    },
  },
  label: {
    '&:hover': {
      color: colors.textPrimary,
    },
    '@media (max-width: 425px)': {
      fontSize: 20,
    },
  },
  linksBox: {
    height: 160,
    backgroundColor: colors.primary,
    paddingRight: 'calc((100vw - 1080px) / 2)',
    [theme.breakpoints.down('sm')]: {
      height: 120,
      padding: '0px 20px',
      justifyContent: 'space-around',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  linkItem: {
    '&:not(:first-of-type)': {
      marginLeft: 30,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
  },
  logoBox: {
    paddingLeft: 'calc((100vw - 1080px) / 2)',
    paddingRight: 'min(20%, 120px)',
    height: '100%',
    clipPath: 'polygon(0 0, 100% 0, 80% 100%, 0% 100%)',
    position: 'absolute',
    top: '24px',
    left: 0,
    backgroundColor: colors.secondary,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

const WideFooterLinks = (props) => {
  const classes = useStyles()
  const [fileLink] = useAssets('BSP.COM text black bg transparent')

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justify="flex-end" classes={{ container: classes.linksBox }}>
        {
          [TEAM, ADVISORS, INVESTORS, NEWSROOM].map((x) => (
            <Grid item key={x.id} classes={{ item: classes.linkItem }}>
              <Link to={x.link}><Typography variant="h4" color="secondary" className={classes.label}>{x.label}</Typography></Link>
            </Grid>
          ))
        }
      </Grid>
      <div className={classes.logoBox}>
        <Logo src={fileLink} />
      </div>
    </div>
  )
}

WideFooterLinks.propTypes = {}
WideFooterLinks.defaultProps = {}

export default WideFooterLinks
