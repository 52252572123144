exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var getUrl = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL___0___ = getUrl(require("../../public/assets/fonts/Womby-Regular.woff2"));
var ___CSS_LOADER_URL___1___ = getUrl(require("../../public/assets/fonts/Womby-Regular.woff"));
var ___CSS_LOADER_URL___2___ = getUrl(require("../../public/assets/fonts/Womby-Regular.ttf"));
var ___CSS_LOADER_URL___3___ = getUrl(require("../../public/assets/fonts/Arial-Rounded-MT.ttf"));
var ___CSS_LOADER_URL___4___ = getUrl(require("../../public/assets/fonts/Arial-Rounded-MT-Bold.ttf"));
// Module
exports.push([module.id, "@font-face{font-family:Womby;src:url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL___2___ + ") format(\"truetype\")}@font-face{font-family:Arial Rounded MT;src:url(" + ___CSS_LOADER_URL___3___ + ") format(\"truetype\")}@font-face{font-family:Arial Rounded MT Bold;src:url(" + ___CSS_LOADER_URL___4___ + ") format(\"truetype\")}a{text-decoration:none;color:inherit;transition:color .1s}a:hover{color:#d83636}", ""]);
