const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  keepSubscriptionClosed: true,
  siteCopy: {},
}

// ------------------------------------
// Actions
// ------------------------------------

const closeSubscription = (bool) => ({
  type: SET_SUBSCRIPTION,
  bool,
})

export const actions = {
  closeSubscription,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_SUBSCRIPTION]: (state, { bool }) => ({
    ...state,
    keepSubscriptionClosed: bool,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
