import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import TextButton from 'components/TextButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HeaderText from 'components/HeaderText'
import LaunchIcon from '@material-ui/icons/Launch'

import Typography from 'components/Typography'
import contentful from 'utils/contentful'
import pdfIcon from 'assets/icons/pdf.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0px 0px',
  },
}))

const useDocStyles = makeStyles((theme) => ({
  underline: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  pdfIcon: {
    width: 16,
    paddingTop: 5,
  },
  quarterWrapper: {
    margin: '32px 0px',
  },
  quarterInfo: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0',
    '@media (max-width: 425px)': {
      alignItems: 'flex-start',
    },
  },
  quarterLabel: {
    width: 120,
    textAlign: 'left',
  },
  link: {
    textDecoration: 'underline',
  },
  grid: {
    maxWidth: 700,
  },
}))


const InvestorInformation = () => {
  const [assets, setAssets] = useState(null)
  const classes = useDocStyles()

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const res = await contentful.getEntries({
          content_type: 'quarterlyFinancialResults',
        })

        setAssets(res)
      } catch (err) {
        console.log('Could not sort documents.', err)
      }
    }

    // fetchAssets()
  }, [])

  return (
    <Box mb={10}>
      <Box mb={4}>
        <HeaderText variant="h4" color="secondary">Investor Information</HeaderText>
      </Box>
      <div>
        <Grid container spacing={1} classes={{ container: classes.grid }}>
          <Grid item xs={12} sm={6}><Typography variant="h5" color="secondary">Exchange</Typography></Grid>
          <Grid item xs={12} sm={6}><Typography color="secondary">TSX Venture</Typography></Grid>

          <Grid item xs={12} sm={6}><Typography variant="h5" color="secondary">TSX Symbol</Typography></Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.link} component="a" color="secondary" href="https://money.tmx.com/en/quote/BP" target="_blank" rel="noopener noreferrer">BP.V</Typography>
          </Grid>

          <Grid item xs={12} sm={6}><Typography variant="h5" color="secondary">Transfer Agent</Typography></Grid>
          <Grid item xs={12} sm={6}><Typography color="secondary">TSX Trust Company</Typography></Grid>

          <Grid item xs={12} sm={6}><Typography variant="h5" color="secondary">Fiscal Year End</Typography></Grid>
          <Grid item xs={12} sm={6}><Typography color="secondary">December 31</Typography></Grid>

          <Grid item xs={12} sm={6}><Typography variant="h5" color="secondary">Auditors</Typography></Grid>
          <Grid item xs={12} sm={6}><Typography color="secondary">MNP LLP</Typography></Grid>
        </Grid>
        <Box mt={3} style={{ display: 'inline-block' }}>
          <Typography
            component="a"
            variant="h5"
            color="secondary"
            href="https://www.sedar.com/DisplayProfile.do?lang=EN&issuerType=03&issuerNo=00021955"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid item>SEDAR PROFILE</Grid>
              <Grid item>
                <LaunchIcon style={{ display: 'block' }} color="secondary" />
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </div>
    </Box>
  )
}

export default InvestorInformation
