import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'
import { colors } from 'theme'

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '16px 0px',
    position: 'relative',
    '&:after': {
      width: '100%',
      maxWidth: 'calc(1200px + (100vw - 1200px) / 2 - 100px)',
      height: '100%',
      content: '""',
      backgroundColor: colors.primary,
      clipPath: 'polygon(5vw 0, 100% 0, 100% 100%, 0 100%)',
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    margin: '0px auto',
    position: 'relative',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fieldWrapper: {
    maxWidth: 540,
    width: '100%',
    padding: '0px 20px 0px 8vw',
  },
  input: {
    minHeight: 48,
    fontSize: 18,
    border: '1px solid #00181c',
    fontFamily: 'Womby',
  },
  icon: {
    opacity: 0.5,
  },
})

const BlogSearchBar = (props) => {
  const { value, onChange } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.fieldWrapper}>
          <TextField
            fullWidth
            value={value}
            placeholder="Search"
            onChange={onChange}
            variant="outlined"
            InputProps={{
              classes: { root: classes.input },
              endAdornment: (
                <InputAdornment position="end">
                  <Search className={classes.icon} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  )
}

BlogSearchBar.propTypes = {}
BlogSearchBar.defaultProps = {}

export default BlogSearchBar
