/**
 * Fetches the copy from CMS
 */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useSiteCopy from 'utils/useSiteCopy'

/**
 *
 * @param {Function} getValues callback function to get the rawValues from useSiteCopy
 * @returns array of strings
 */
const Copywrite = (props) => {
  const { id, getValues } = props
  const [copy, loading, rawValues] = useSiteCopy(id)

  useEffect(() => {
    getValues(rawValues)
  }, [rawValues])

  return (
    loading ? <div>Loading...</div> : copy.body
  )
}

Copywrite.propTypes = {
  id: PropTypes.string,
  getValues: PropTypes.func,
}

Copywrite.defaultProps = {
  id: '',
  getValues: () => {},
}

export default Copywrite
