import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    border: '1px solid transparent',
    transition: 'box-shadow 150ms ease-in',
    '&:hover': {
      border: '1px solid #fff',
    },
  },
})

const CardHover = (props) => {
  const { disabled } = props
  const classes = useStyles()

  return (
    <div className={clsx({ [classes.root]: !disabled })}>{props.children}</div>
  )
}

CardHover.propTypes = {}
CardHover.defaultProps = {}

export default CardHover
