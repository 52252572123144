import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { colors } from 'theme'
import HeaderText from 'components/HeaderText'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = {
  container: {
    width: '100%',
    marginTop: 50,
  },
}

const useStyles = makeStyles({
  root: {
    margin: '80px 0px',
  },
  tradeViewText: {
  },
})

const StockPrice = (props) => {
  const {
    symbols, href, label, widgetOptions,
  } = props
  const [pageLoaded, setPageLoaded] = useState(false)
  const classes = useStyles()

  const loadWidget = () => {
    new window.TradingView.MediumWidget({ // eslint-disable-line
      container_id: 'tv-medium-widget',
      symbols,
      greyText: 'Quotes by',
      width: '100%',
      height: '400',
      locale: 'en',
      ...widgetOptions,
    })
  }

  const onPageLoad = () => {
    setPageLoaded(true)
  }

  useEffect(() => {
    window.addEventListener('load', onPageLoad)

    return () => {
      window.removeEventListener('load', onPageLoad)
    }
  }, [])

  useEffect(() => {
    loadWidget()
  }, [pageLoaded])

  return (
    <div className={classes.root}>
      <HeaderText color="secondary" variant="h4">Stock Chart</HeaderText>
      <div style={styles.container} className="tradingview-widget-container">
        <div id="tv-medium-widget" />
        <div className="tradingview-widget-copyright">
          <Typography component="a" rel="noopener" target="_blank" color="secondary">
            {label}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default StockPrice
