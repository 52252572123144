import React, { useState } from 'react'

import { makeStyles, styled } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import HeaderText from 'components/HeaderText'
import Newsroom from 'components/Newsroom'
import SocialMediaBar from 'components/SocialMediaBar'
import Copy from 'components/Copywrite'
import { colors } from 'theme'
import PhoneIcon from 'assets/icons/phone-icon.png'
import MailIcon from 'assets/icons/mail-icon.png'
import LocationIcon from 'assets/icons/location-icon.png'
import Copyright from './Copyright'
import WideFooterLinks from './WideFooterLinks'

const InfoRow = styled(Grid)({
  marginLeft: 36,
})

const PhoneInfoRow = styled(Grid)({
  margin: '10px 0px',
})

const Icon = styled('img')({
  width: 20,
  height: 20,
  verticalAlign: 'middle',
  marginRight: 16,
})

const InfoRowText = ({ children, ...rest }) => <Typography component="div" color="secondary" variant="body2" {...rest}>{children}</Typography>
const LinkRowText = ({ children, ...rest }) => <Typography color="secondary" variant="body2" component="a" style={{ lineHeight: 2 }} {...rest}>{children}</Typography>

const StyledInfoRowText = styled(InfoRowText)({
  lineHeight: 2,
})

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: colors.background,
    background: colors.radialGradient,
  },
  headerText: {
    marginBottom: 32,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1080,
    padding: '80px 0px',
    '@media (max-width: 1080px)': {
      padding: '40px',
      width: '100%',
    },
    '@media (max-width: 425px)': {
      padding: '40px 20px',
      width: '100%',
    },
    zIndex: 9999,
    margin: 'auto',
  },
  newsRoom: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
  registeredOffice: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.footer}>
        <Grid container alignItems="flex-start">
          <Grid container alignItems="flex-start" item sm={12} md={9}>
            <Grid item xs={12}>
              <div className={classes.headerText}>
                <HeaderText color="secondary" variant="h3">Contact Us</HeaderText>
              </div>
            </Grid>
            <Grid container item xs={12} sm={6}>
              <Grid container alignItems="center" item xs={12}>
                <Grid item><Icon src={LocationIcon} /></Grid>
                <Grid item><StyledInfoRowText><Copy id="dv-1" />:</StyledInfoRowText></Grid>
              </Grid>
              <InfoRow item xs={12}><StyledInfoRowText><Copy id="dv-2" /></StyledInfoRowText></InfoRow>
              <InfoRow item xs={12}>
                <StyledInfoRowText>
                  <Copy id="dv-3" />
                </StyledInfoRowText>
              </InfoRow>
              <InfoRow item xs={12}><StyledInfoRowText><Copy id="dv-4" /> <Copy id="dv-5" /></StyledInfoRowText></InfoRow>
              <InfoRow item xs={12}><StyledInfoRowText><Copy id="dv-6" /></StyledInfoRowText></InfoRow>
              {/* <PhoneInfoRow container alignItems="center" item xs={12}>
                <Grid item><Icon src={PhoneIcon} /></Grid>
                <Grid item>
                  <StyledInfoRowText>
                    <Copy id="dv-7" />
                  </StyledInfoRowText>
                </Grid>
              </PhoneInfoRow> */}
              <Grid container alignItems="center" item xs={12}>
                <Grid item><Icon src={MailIcon} /></Grid>
                <Grid item>
                  <StyledInfoRowText>
                    <Copy id="dv-8" />
                  </StyledInfoRowText>
                </Grid>
              </Grid>
              {/* <InfoRow item xs={12}>
                <StyledInfoRowText>
                  <Copy id="dv-9" />
                </StyledInfoRowText>
              </InfoRow> */}
            </Grid>
            <Grid classes={{ container: classes.registeredOffice }} container item xs={12} sm={6}>
              <Grid container alignItems="center" item xs={12}>
                <Grid item><Icon src={LocationIcon} /></Grid>
                <Grid item><StyledInfoRowText><Copy id="re-1" />:</StyledInfoRowText></Grid>
              </Grid>
              <InfoRow item xs={12}><StyledInfoRowText><Copy id="re-2" /></StyledInfoRowText></InfoRow>
              <InfoRow item xs={12}>
                <StyledInfoRowText>
                  <Copy id="re-3" />
                </StyledInfoRowText>
              </InfoRow>
              <InfoRow item xs={12}><StyledInfoRowText><Copy id="re-4" />, <Copy id="re-5" /></StyledInfoRowText></InfoRow>
              <InfoRow item xs={12}><StyledInfoRowText><Copy id="re-6" /></StyledInfoRowText></InfoRow>
              {/* <PhoneInfoRow container alignItems="center" item xs={12}>
                <Grid item><Icon src={PhoneIcon} /></Grid>
                <Grid item>
                  <StyledInfoRowText>
                    <Copy id="re-7" />
                  </StyledInfoRowText>
                </Grid>
              </PhoneInfoRow> */}
              <InfoRow item xs={12}>
                <SocialMediaBar follow orientation="horizontal" ContainerProps={{ style: { margin: '0px -12px' } }} />
              </InfoRow>
            </Grid>
          </Grid>
          <Grid item sm={12} md={3} classes={{ item: classes.newsRoom }}>
            <Newsroom />
          </Grid>
        </Grid>
      </div>
      <WideFooterLinks />
      <Copyright />
    </div>
  )
}

export default Footer
