import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { colors } from 'theme'

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
  text: {
    zIndex: 1,
    position: 'relative',
  },
  polygon: {
    width: 'calc(100% + 30px)',
    maxWidth: 'calc(100vw - 40px)',
    height: 16,
    marginTop: -10,
    backgroundColor: colors.primary,
    clipPath: 'polygon(0 0, 100% 0, 85% 100%, 0% 100%)',
  },
})

const HeaderText = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.text} {...props} />
      <div className={classes.polygon} />
    </div>
  )
}

HeaderText.propTypes = {}
HeaderText.defaultProps = {}

export default HeaderText
