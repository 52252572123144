const theme = {}

export const MAX_WIDTH = 1080

export const colors = {
  primary: '#d83636',
  primaryDull: '#d83636',
  secondary: '#fff',
  background: '#171717',
  textPrimary: '#171717',
  textSecondary: '#fff',
  textDescription: 'rgba(23, 23, 23, 0.4)',
  radialGradient: 'radial-gradient(circle at top, #242424, #171717 40%)',
}

export default theme
