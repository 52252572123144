import React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'theme'
import { makeStyles } from '@material-ui/core/styles'
import RecentBlogPosts from '../components/RecentBlogPosts'
import BlogSearchResults from '../components/BlogSearchResults'
import BlogSearchBar from '../components/BlogSearchBar'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '64px 20px',
    maxWidth: 1200,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '64px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '28px 20px',
    },
  },
  bg: {
    backgroundColor: colors.background,
  },
}))

const Home = (props) => {
  const {
    posts,
    query,
    onSearchChange,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.bg}>
      <BlogSearchBar
        value={query}
        onChange={onSearchChange}
      />
      <div className={classes.root}>
        {
          (query.length >= 2)
            ? <BlogSearchResults posts={posts} searchQuery={query} />
            : <RecentBlogPosts posts={posts} />
        }
      </div>
    </div>
  )
}

Home.propTypes = {}
Home.defaultProps = {}

export default Home
