import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'
import Email from '@material-ui/icons/Email'
import LocationOn from '@material-ui/icons/LocationOn'
import { colors } from 'theme'
import Polygon from 'components/Polygon'
import HeaderText from 'components/HeaderText'

import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: { },
  container: {
    marginTop: 60,
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.background,
    flex: 1,
    padding: '50px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0px 0px',
    },
  },
  contactContainer: {
    display: 'flex',
    marginBottom: 16,
    alignItems: 'flex-start',
  },
  title: {
    width: 130,
    minWidth: 130,
    lineHeight: 1.9,
    [theme.breakpoints.down('sm')]: {
      width: 30,
      minWidth: 30,
      fontSize: 14,
      lineHeight: 1.5,
    },
  },
  value: {
    lineHeight: 'calc(18px * 1.9)',
    textDecoration: 'none',
  },
  icon: {
    color: colors.primary,
    marginRight: 15,
    height: 'calc(18px * 1.9)',
  },
  polygon: {
    marginBottom: 32,
  },
}))

const InvestorRelationsContact = (props) => {
  const classes = useStyles()
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return (
    <div className={classes.container}>
      <HeaderText variant="h4" color="secondary">Investor Relations Contact</HeaderText>
      <div className={classes.contact}>
        <Typography variant="h3" color="secondary">Sean Hodgins</Typography>
        <Typography variant="h5" color="secondary">Chief Financial Officer</Typography>
        <Polygon className={classes.polygon} />
        <div className={classes.contactContainer}>
          {
            matchesXs
              ? <Email className={classes.icon} />
              : <Typography variant="h5" color="secondary" className={classes.title}>EMAIL</Typography>
          }
          <Typography variant="body2" color="secondary" component="a" href="mailto:info@backstageplay.com" target="_blank" rel="noopenner noreferrer" className={classes.value}>
            info@backstageplay.com
          </Typography>
        </div>
        <div className={classes.contactContainer}>
          {
            matchesXs
              ? <LocationOn className={classes.icon} />
              : <Typography variant="h5" color="secondary" className={classes.title}>ADDRESS</Typography>
          }
          <div>
            <Typography className={classes.value} variant="body2" color="secondary">4145 North Service Rd, Suite 200</Typography>
            <Typography className={classes.value} variant="body2" color="secondary">Burlington, ON</Typography>
            <Typography className={classes.value} variant="body2" color="secondary">L7L 6A3</Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

InvestorRelationsContact.propTypes = {}
InvestorRelationsContact.defaultProps = {}

export default InvestorRelationsContact
