import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { colors } from 'theme'

const useStyles = makeStyles((theme) => ({
  root: {
    transition: 'color 100ms',
    '&:hover': {
      '& #title': {
        color: colors.primary,
      },
    },
  },
  linkText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

const SmallNewsCard = ({
  createdAt, title, tags, link,
}) => {
  const classes = useStyles()

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <Typography id="title" className={classes.linkText} variant="body2" color="secondary">{title}</Typography>
        </Grid>
        <Grid item><Typography style={{ fontSize: 14, color: '#fff', opacity: 0.4 }}>{format(createdAt, 'yyyy-MM-ddd')}</Typography></Grid>
      </Grid>
    </a>
  )
}

SmallNewsCard.propTypes = {}
SmallNewsCard.defaultProps = {}

export default SmallNewsCard
