import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { colors } from 'theme'
import { makeStyles, styled } from '@material-ui/core/styles'
import clsx from 'clsx'
import Menu from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Typography from 'components/Typography'
import TextButton from 'components/TextButton'
import Logo from 'assets/images/bsp-full-logo.png'
import { headerLinks } from 'utils/useRoutes'
import useAssets from 'utils/useAssets'
import MobileDrawer from './MobileDrawer'

export const HEADER_HEIGHT = {
  lg: 160,
  md: 160,
  sm: 80,
}

const elevatedComponentStyles = {
  color: colors.primary,
  position: 'relative',
  zIndex: 300,
}

const elevatedColoredComponentStyles = {
  color: colors.primary,
  position: 'relative',
  zIndex: 300,
}

// elevate logo and menu icon above mobile drawer
const MenuIcon = styled(Menu)(elevatedComponentStyles)
const OpenIcon = styled(Menu)(elevatedColoredComponentStyles)
const ElevatedIcon = styled('img')(elevatedComponentStyles)

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    backgroundColor: colors.background,
    height: HEADER_HEIGHT.lg,
    position: 'fixed',
    top: 0,
    left: 0,
    '@media (max-width: 768px)': {
    },
    [theme.breakpoints.down('sm')]: {
      height: HEADER_HEIGHT.sm,
    },
    zIndex: 9999,
  },
  root: {
    display: 'flex',
    backgroundColor: colors.background,
    maxWidth: 1080,
    margin: 'auto',
    height: '100%',
  },
  rootContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0px 20px',
    overflow: 'hidden',
  },
  menuItems: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  menuLabel: {
    margin: '0 24px',
    fontSize: 18,
    color: colors.textSecondary,
    transition: 'color 100ms ease-in-out',
    '&:hover': {
      color: colors.primary,
    },
  },
  link: {
    textDecoration: 'none',
  },
  moreItemLink: {
    display: 'block',
    marginBottom: 16,
    textDecoration: 'none',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  moreItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  moreMenuItem: {
    cursor: 'default',
    marginLeft: 12,
    marginTop: 20,
    color: colors.textPrimary,
    '&:hover': {
      color: colors.primary,
    },
  },
  moreItemIcon: {
    marginRight: 24,
  },
  moreLabel: {
    color: colors.textPrimary,
    '&:hover': {
      color: colors.primary,
    },
  },
  active: {
    color: colors.primary,
  },
  signInButton: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  menuIcon: {
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'block',
      marginLeft: 'auto',
      padding: 0,
    },
  },
  separator: {
    marginTop: 15,
    width: '100%',
    borderBottom: '1px solid #cccccd',
  },
  icon: {
    height: '100%',
  },
  logo: {
    height: '100%',
    width: 250,
  },
  pageblock: ({ isDrawerOpen }) => ({
    display: isDrawerOpen ? 'block' : 'none',
    position: 'absolute',
    zIndex: 300,
    left: 0,
    top: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'black',
    opacity: 0.1,
  }),
}))

const MenuItem = ({ children, active, link }) => {
  const classes = useStyles()

  return (
    <Link to={link} className={classes.link}>
      <TextButton>
        <Typography variant="h5" className={clsx(classes.menuLabel, { [classes.active]: active })}>{children}</Typography>
      </TextButton>
    </Link>
  )
}

const AppHeader = (props) => {
  const { location } = props
  const [isDrawerOpen, toggleDrawer] = useState(false)
  const classes = useStyles({ isDrawerOpen })
  const [fileLink] = useAssets('BSP text white bg transparent')

  const handleToggleDrawer = () => {
    toggleDrawer(!isDrawerOpen)
    document.body.style.overflowY = !isDrawerOpen ? 'hidden' : 'auto'
  }

  const matchPath = (path) => {
    const matchesPath = location.pathname === path
    const matchesNestedPath = location.pathname.includes(path)

    // workaround to avoid nested always being true with homepage
    if (path === '/') return matchesPath

    return matchesPath || matchesNestedPath
  }

  return (
    <div className={classes.header}>
      <div className={classes.root}>
        <div className={classes.rootContainer}>
          <div>
            <Link to="/">
              <ElevatedIcon src={fileLink} alt="backstageplay" className={classes.logo} />
            </Link>
          </div>
          <div className={classes.menuItems}>
            {
              headerLinks.map((x) => <MenuItem key={x.id} link={x.link} active={matchPath(x.link)}>{x.label}</MenuItem>)
            }
          </div>
          <IconButton onClick={handleToggleDrawer} className={classes.menuIcon}>
            {
              isDrawerOpen
                ? <OpenIcon color="secondary" />
                : <MenuIcon color="secondary" />
            }
          </IconButton>
          <div className={classes.pageblock} onClick={handleToggleDrawer} />
          <MobileDrawer open={isDrawerOpen} onClose={handleToggleDrawer} />
        </div>
      </div>
    </div>
  )
}

export default AppHeader
