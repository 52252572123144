import React, { useState, useEffect } from 'react'

/**
 * @param {String} gameNames semi-colon separated list of games to display. They must be spelled correctly but are not case sensitive.
 * @param {['streams']} endpoint lambda endpoint
 * @param {{ gameNames: [String] }} params param to be URL encoded
 */
const useTwitch = () => {
  const loadTwitch = async ({ endpoint, params }) => {
    const payload = params

    try {
      // format gameNames to API standard
      payload.gameNames = params.gameNames.join(';')

      const url = new URLSearchParams(payload)

      const res = await fetch(
        `${process.env.LAMBDA_API}/${endpoint}?${url.toString()}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-secret': process.env.LAMBDA_SECRET,
          },
        },
      )

      const parsedData = await res.json()

      return parsedData
    } catch (err) {
      console.log(err)
    }

    return null
  }

  return loadTwitch
}

export default useTwitch
