import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Pagination from '@material-ui/lab/Pagination'
import Typography from 'components/Typography'
import { colors } from 'theme'
import BlogListItem from './BlogListItem'

const useStyles = makeStyles({
  selectedItem: {
    '& button.MuiPaginationItem-root': {
      backgroundColor: '#f4ece2',
    },
    '& button.Mui-selected': {
      color: '#fff',
      backgroundColor: colors.primary,
    },
    '& button.Mui-selected:hover': {
      backgroundColor: colors.primaryDull,
    },
  },
})

const POSTS_PER_PAGE = 5

const RecentBlogPosts = (props) => {
  const { posts } = props
  const [page, setPage] = useState(1)
  const classes = useStyles()

  const pages = Math.ceil(posts.length / POSTS_PER_PAGE)

  const postsOnCurrentPage = posts.slice((page - 1) * POSTS_PER_PAGE, page * POSTS_PER_PAGE)

  const onChange = (e, nextPage) => {
    setPage(nextPage)
    window.scroll({
      behavior: 'auto',
      top: 0,
    })
  }

  return (
    <div>
      {
        postsOnCurrentPage.length
          ? (
            <>
              <Grid container>
                {
                  postsOnCurrentPage.map((x, i) => (
                    <Grid key={x.link} item xs={12} style={{ marginBottom: 32 }}>
                      <BlogListItem post={x} tabIndex={i + 1} />
                    </Grid>
                  ))
                }
              </Grid>
              <Grid container justify="center">
                <Pagination
                  count={pages}
                  page={page}
                  shape="rounded"
                  size="large"
                  onChange={onChange}
                  classes={{ root: classes.selectedItem }}
                />
              </Grid>
            </>
          )
          : <Typography weight="light">There aren&apos;t any posts right now!</Typography>
      }
    </div>
  )
}

RecentBlogPosts.propTypes = {}
RecentBlogPosts.defaultProps = {}

export default RecentBlogPosts
