import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import EngageBG from 'assets/images/games-bg.png'
import { colors, MAX_WIDTH } from 'theme'
import Polygon from 'components/Polygon'
import HeaderText from 'components/HeaderText'
import Copy from 'components/Copywrite'
import Assets from 'components/Assets'
import Contentful from 'utils/contentful'
import useTwitch from 'utils/useTwitch'
import TwitchPlayer from './TwitchPlayer'

const useEngageStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    minHeight: 300,
    height: 'calc(100vh - 160px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 80px)',
    },
    '&:after': {
      content: '""',
      backgroundImage: `url(${EngageBG})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: '100%',
      right: 0,
      maxWidth: '50%',
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
  textBG: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(100vh - 160px)',
    paddingLeft: `calc((100vw - ${MAX_WIDTH}px) / 2)`,
    clipPath: 'polygon(0 0, 100% 0, 70% 100%, 0% 100%)',
    backgroundColor: colors.background,
    marginRight: `calc(((100vw - ${MAX_WIDTH}px) / 2) + 120px)`,
    position: 'relative',
    zIndex: 10,
    '@media (max-width: 1080px)': {
      paddingLeft: 20,
    },
    '@media (max-width: 768px)': {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxWidth: '90%',
    },
  },
  textBox: {
    marginRight: '25%',
  },
}))

const Engage = () => {
  const classes = useEngageStyles()

  return (
    <div className={classes.root}>
      <div className={classes.textBG}>
        <div className={classes.textBox}>
          <Typography variant="h1" color="secondary" style={{ marginBottom: 40 }}>
            <Copy id="eg-1" />
          </Typography>
          <Typography variant="subtitle2" color="secondary">
            <Copy id="eg-2" />
          </Typography>
          <Polygon />
        </div>
      </div>
    </div>
  )
}

const useLeadingStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.background,
    width: '100%',
    padding: '100px 20px',
  },
  container: {
    maxWidth: MAX_WIDTH,
    margin: '0px auto',
  },
  video: {
    width: '100%',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 40,
    },
  },
}))

const LeadingWeb = () => {
  const classes = useLeadingStyles()

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" className={classes.container}>
        <Grid item sm={12}>
          <Typography variant="subtitle2" color="secondary">
            <Copy id="eg-3" />
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

const useUniteStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.primary,
    width: '100%',
    padding: '60px 20px',
  },
  container: {
    maxWidth: MAX_WIDTH,
    margin: '0px auto',
  },
  video: {
    width: '100%',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 40,
    },
  },
}))

const Unite = () => {
  const classes = useUniteStyles()

  return (
    <>
      <div className={classes.root}>
        <Grid container alignItems="center" className={classes.container}>
          <Grid item sm={12} md={6}>
            <Typography variant="h1" color="secondary" className={classes.title}>
              <Copy id="un-1" />
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography variant="subtitle2" color="secondary">
              <Copy id="un-2" />
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.video} />
    </>
  )
}

const useRewardStyles = makeStyles((theme) => ({
  root: {
    maxWidth: MAX_WIDTH,
    margin: '0px auto',
    padding: '80px 0px',
    background: colors.radialGradient,
    '@media (max-width: 1080px)': {
      padding: '80px 20px',
    },
  },
  playerWrapper: {
    marginBottom: 32,
  },
  moreStreamsBox: {
    marginTop: 32,
  },
  title: {
    marginBottom: 20,
  },
  streamOverlay: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
  },
  liveBadge: {
    fontSize: 12,
    position: 'absolute',
    fontFamily: 'Womby',
    top: 15,
    left: 15,
    borderRadius: 2,
    padding: '2px 6px',
    backgroundColor: 'red',
    zIndex: 1,
  },
  gameBadge: {
    fontSize: 12,
    opacity: 0.7,
    margin: '4px 0px',
  },
  streamTitle: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const TWITCH_STREAMS_LINK = 'https://www.twitch.tv/directory/game'
const MAX_STREAMS = 5

const Reward = () => {
  const classes = useRewardStyles()
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const [games, setGames] = useState(null)
  const [streams, setStreams] = useState(null)
  const loadTwitch = useTwitch()
  const [mainChannelIndex, setMainChannelIndex] = useState(0)

  const getGamePageLink = (game = '') => `${TWITCH_STREAMS_LINK}/${encodeURIComponent(game)}`
  const MoreStreamsLink = getGamePageLink(games ? games[0] : '')

  const fetchGameName = async () => {
    try {
      const res = await Contentful.getEntries({ content_type: 'mainGames' })
      setGames(res.items[0].fields.games)
    } catch (err) {
      console.log(err)
    }
  }

  const validateStream = (stream, game) => (
    stream.type === 'live'
    && stream.game_name === game
    && !stream.is_mature
    && stream.viewer_count >= 10
  )

  const THUMBNAIL_WIDTH = 400
  const getStreamThumbnail = (url) => (
    url
      .replace('{width}', 400)
      .replace('{height}', THUMBNAIL_WIDTH * 0.5625) // 16/9 aspect ratio
  )

  const fetchLiveStreams = async () => {
    try {
      const res = await loadTwitch({ endpoint: 'streams', params: { gameNames: games } })

      let diversifiedStreams = []

      const itemsPerBucket = Math.floor(MAX_STREAMS / games.length)
      const bucketsWithExtra = MAX_STREAMS % games.length

      games.forEach((x, i) => {
        const hasExtra = i < bucketsWithExtra

        try {
          // res = [{ gameName: [] }, { gameName2: [] }, ...]
          const selectedStreams = (res[i][x]
            .filter((s) => validateStream(s, x))
            .slice(0, hasExtra ? itemsPerBucket + 1 : itemsPerBucket)
          )

          diversifiedStreams = [...diversifiedStreams, ...selectedStreams]
        } catch (err) {
          console.log('Error selecting streams to show')
        }
      })

      setStreams(diversifiedStreams)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchGameName()
  }, [])

  useEffect(() => {
    if (games) {
      fetchLiveStreams()
    }
  }, [games])

  const mainStream = streams ? streams[mainChannelIndex] : null

  const onSelectStream = (e, i) => {
    e.preventDefault()
    setMainChannelIndex(i)
  }

  return (
    <div className={classes.root}>
      <Polygon />
      <Typography variant="h1" color="secondary" className={classes.title}>
        <Copy id="rw-1" />
      </Typography>
      <Typography variant="subtitle2" color="secondary" style={{ marginBottom: 80 }}>
        <Copy id="rw-2" />
      </Typography>
      <div className={classes.playerWrapper}>
        { mainStream && <TwitchPlayer channel={mainStream.user_name} />}
        {
          streams ? (
            <Grid classes={{ container: classes.moreStreamsBox }} container spacing={2}>
              {
                streams.map((x, i) => {
                  if (i === mainChannelIndex) return null

                  return (
                    <Grid item xs={6} sm={3} key={x.id} style={{ position: 'relative' }}>
                      <img // eslint-disable-line
                        src={getStreamThumbnail(x.thumbnail_url)}
                        className={classes.streamOverlay}
                        alt={x.user_name}
                        onClick={(e) => { onSelectStream(e, i) }}
                      />
                      <Typography color="secondary" className={classes.liveBadge}>LIVE</Typography>
                      <Typography
                        component="a"
                        href={getGamePageLink(x.game_name)}
                        target="_blanks"
                        color="secondary"
                        className={classes.gameBadge}
                      >
                        {x.game_name}
                      </Typography>
                      <Typography color="secondary" className={classes.streamTitle}>
                        {x.title}
                      </Typography>
                    </Grid>
                  )
                })
              }
            </Grid>
          ) : null
        }
      </div>
      <HeaderText component="a" href={MoreStreamsLink} target="_blank" color="secondary" variant={matchesXs ? 'h4' : 'h3'}>WATCH MORE PLAY2EARN STREAMS</HeaderText>
    </div>
  )
}

const useConsolidateStyles = makeStyles((theme) => ({
  root: {
    padding: '80px 0px',
  },
  rootContainer: {
    display: 'flex',
    position: 'relative',
    minHeight: 200,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
    },
  },
  container: {
    width: '100%',
    maxWidth: MAX_WIDTH,
    margin: '0px auto',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    position: 'relative',
    '@media (max-width: 1080px)': {
      padding: '40px 20px',
    },
  },
  customPolygon: {
    position: 'absolute',
    height: '100%',
    marginTop: 0,
    top: 0,
    left: 0,
    width: `calc(((${MAX_WIDTH}px / 2) + ((100vw - ${MAX_WIDTH}px) / 2)) + 40px)`,
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      minWidth: 365,
    },
  },
  descriptionBox: {
    // display: 'flex',
  },
}))

const Consolidate = () => {
  const classes = useConsolidateStyles()

  return (
    <div className={classes.root}>
      <div className={classes.rootContainer}>
        <div className={classes.container}>
          <Typography color="secondary" variant="h2">
            <Copy id="cn-1" />
          </Typography>
        </div>
        <Polygon className={classes.customPolygon} angle="90%" />
      </div>
      <div className={classes.rootContainer}>
        <div className={classes.container}>
          <Typography color="secondary" variant="subtitle2" className={classes.descriptionBox}>
            <Copy id="cn-2" />
          </Typography>
        </div>
      </div>
    </div>
  )
}

const Landing = (props) => (
  <div>
    <Engage />
    <LeadingWeb />
    <Unite />
    <Reward />
    <Consolidate />
  </div>
)

Landing.propTypes = {}
Landing.defaultProps = {}

export default Landing
