import React from 'react'
import Landing from '../Pages/Landing'
import Investors from '../Pages/Investors'
import Blog from '../Pages/Blog'
import Team from '../Pages/Team'

export const LANDING = {
  label: 'Landing', link: '/', component: Landing, exact: true,
}
export const TEAM = {
  id: 'management', label: 'Team', link: '/team', component: Team,
}

export const ADVISORS = {
  id: 'advisors',
  label: 'Advisors',
  link: '/advisors',
  component: (props) => <Team contentType="advisorsOrder" introHeader={<span>COMING<br />SOON</span>} introDescription="" {...props} />,
}

export const ABOUT_US = { id: 'about-us', label: 'About Us', link: '/' }

export const NEWSROOM = {
  id: 'newsroom', label: 'Newsroom', link: '/newsroom', component: Blog,
}
export const INVESTORS = {
  id: 'investors', label: 'Investors', link: '/investors', component: Investors,
}

export default () => [
  LANDING,
  TEAM,
  ADVISORS,
  INVESTORS,
  NEWSROOM,
]

export const company = [
  ABOUT_US,
  TEAM,
  NEWSROOM,
]

export const investors = [
  { id: 'documents', label: 'Governance Documents', link: '/investors#governance-documents' },
  { id: 'results', label: 'Financial Results', link: '/investors#financial-results' },
  { id: 'investors', label: 'Contact Us', link: '/investors#contact' },
]

export const headerLinks = [
  ABOUT_US,
  TEAM,
  ADVISORS,
  INVESTORS,
  NEWSROOM,
]

export const drawerOptions = [
  ABOUT_US,
  TEAM,
  ADVISORS,
  INVESTORS,
  NEWSROOM,
]
