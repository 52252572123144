import React, {
  useEffect, useState, useMemo, useRef,
} from 'react'
import union from 'lodash.union'
import debounce from 'lodash.debounce'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import Home from './Home'
import BlogPage from './BlogPage'
import { fetchBlogPosts, fetchPostsFromRSS } from './blog.module'

const Blog = (props) => {
  const [posts, setPosts] = useState([])
  const [query, setQuery] = useState('')

  const trackSearch = (keyword) => {
    gtag(
      'event',
      keyword,
      {
        event_category: 'engagement',
        event_label: 'blog_search_term',
      },
    )
  }

  const debouncedTracker = useRef(debounce(trackSearch, 500))

  useEffect(() => {
    if (query.length > 2) {
      // debouncedTracker.current(query)
    }
  }, [query])

  const onSearchChange = (e) => {
    setQuery(e.target.value)
  }

  useEffect(() => {
    const getPosts = async () => {
      try {
        // const res = await fetchBlogPosts()
        const rssPosts = await fetchPostsFromRSS()

        const validPosts = rssPosts.posts.length ? rssPosts.posts : rssPosts.data

        setPosts(validPosts)
      } catch (err) {
        console.log(err)
      }
    }

    getPosts()
  }, [])

  return (
    <>
      <Route
        exact
        path="/newsroom"
        render={(props) => (
          <Home
            {...props}
            posts={posts}
            query={query}
            onSearchChange={onSearchChange}
          />
        )}
      />
      {/* <Route
        exact
        path="/newsroom/:id/:title"
        render={(props) => <BlogPage {...props} posts={posts} />}
      />
      <Route
        exact
        path="/newsroom/:urlPermalink"
        render={(props) => <BlogPage {...props} posts={posts} />}
      /> */}
    </>
  )
}

Blog.propTypes = {}
Blog.defaultProps = {}

export default Blog
