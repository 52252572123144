// used to display the blog title in a list
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '24px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '16px 0px 0px',
    },
  },
}))

const BlogTitle = (props) => {
  const { title, fontSize, color = 'primary' } = props
  const classes = useStyles()

  const mapFontToVariant = {
    small: 'h4',
    normal: 'h4',
    large: 'h3',
  }

  return (
    <Typography className={classes.root} color={color} variant={mapFontToVariant[fontSize]}>{title}</Typography>
  )
}

BlogTitle.propTypes = {
  fontSize: PropTypes.oneOf([
    'small',
    'normal',
    'large',
  ]),
}
BlogTitle.defaultProps = {
  fontSize: 'normal',
}

export default BlogTitle
