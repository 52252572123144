import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Close from '@material-ui/icons/Close'

import SubscriptionField from 'components/SubscriptionField'
import Typography from '@material-ui/core/Typography'
import { colors } from 'theme'

import { actions } from '../../app.module'

const useStyles = makeStyles((theme) => ({
  animationWrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 9999,
    transition: 'bottom 800ms',
  },
  hidden: {
    bottom: '-100%',
  },
  root: {
    backgroundColor: colors.background,
    position: 'relative',
    boxShadow: '0 0 50px 0 rgba(0, 0, 0, 0.3)',
  },
  topAccent: {
    width: '100%',
    height: 10,
    // background: `linear-gradient(98deg, ${colors.primary} -3%, rgba(255, 255, 255, 0) 50%)`,
    background: colors.primary,
  },
  container: {
    maxWidth: 1000,
    margin: '0px auto',
    padding: '96px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '48px 50px',
    },
  },
  header: {
    marginBottom: 24,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  description: {
    lineHeight: 2,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}))

const SubscriptionPopup = (props) => {
  const { delay } = props
  const [show, setShow] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const keepClosed = useSelector(({ app }) => app.keepSubscriptionClosed)
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const onClose = () => {
    setShow(false)
    dispatch(actions.closeSubscription(true))
  }

  useEffect(() => {
    if (!keepClosed) {
      const id = setTimeout(() => {
        setShow(true)
        clearTimeout(id)
      }, delay || 12000)
    }
  }, [delay, keepClosed])

  return (
    <div
      className={clsx(
        classes.animationWrapper,
        { [classes.hidden]: !show },
      )}
    >
      <div className={classes.root}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <Close color="secondary" />
        </IconButton>
        <div className={classes.topAccent} />
        <Grid container classes={{ container: classes.container }} spacing={matchesTablet ? 2 : 0}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" color="secondary" className={classes.header}>Subscribe to our newsletter</Typography>
            {!matchesMobile && <Typography variant="subtitle2" color="secondary" className={classes.description}>Receive the latest news and posts directly in your inbox.</Typography>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubscriptionField
              large
              onClose={onClose}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

SubscriptionPopup.propTypes = {}
SubscriptionPopup.defaultProps = {}

export default SubscriptionPopup
