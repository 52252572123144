import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import contentful from 'utils/contentful'
import IconButton from '@material-ui/core/IconButton'
import Download from '@material-ui/icons/GetApp'
import FileSaver from 'file-saver'
import humanize from 'humanize-plus'
import HeaderText from 'components/HeaderText'

import Typography from '@material-ui/core/Typography'

import StockPrice from './StockPrice'
import FinancialResults from './FinancialResults'
import InvestorDeck from './InvestorDeck'
import InvestorInformation from './InvestorInformation'
import InvestorRelationsContaact from './InvestorRelationsContact'

const useDocumentStyles = makeStyles((theme) => ({
  document: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 70px',
    border: '1px solid #fff',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 24px 16px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  size: {
    fontFamily: 'Womby',
  },
  name: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
    },
  },
  download: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: -16,
    },
  },
}))

const Document = ({ name, link, size }) => {
  const classes = useDocumentStyles()

  return (
    <div className={classes.document}>
      <Typography className={classes.name} variant="h5" color="secondary">{name.replace(/([A-Z])/g, ' $1')}</Typography>
      <div className={classes.download}>
        <IconButton onClick={() => FileSaver.saveAs(link)}><Download color="secondary" /></IconButton>
        <Typography color="secondary" className={classes.size}>{humanize.fileSize(size, 2)}</Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1080,
    margin: '0px auto',
    padding: '60px 0px',
    '@media (max-width: 1080px)': {
      padding: '60px 20px',
    },
  },
  documentList: {
    marginTop: 40,
  },
}))

const Investors = (props) => {
  const [docs, setDocs] = useState(null)
  const classes = useStyles()

  const fetchDocuments = async () => {
    try {
      const res = await contentful.getEntries({
        content_type: 'governanceDocuments',
      })

      setDocs(res.items[0].fields)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    // fetchDocuments()
  }, [])

  return (
    <div className={classes.root}>
      {/* <HeaderText color="secondary" variant="h4">Corporate Governance Documents</HeaderText>
      <div className={classes.documentList}>
        {
          docs
            ? (
              Object.entries(docs).map(([key, value]) => {
                if (key === 'title') return null

                const file = value?.fields.file
                return file ? <Document key={key} name={key} link={file.url} size={file.details?.size} /> : null
              })
            )
            : null
        }
      </div> */}
      <InvestorInformation />
      <InvestorDeck />
      <FinancialResults />
      <StockPrice
        href="https://www.tradingview.com/chart/?symbol=TSXV%3ABP"
        symbols={[
          [
            'Backstageplay',
            'TSXV:BP|1m',
          ],
        ]}
        // options from web widget configurator
        // https://www.tradingview.com/widget/symbol-overview/
        widgetOptions={{
          colorTheme: 'dark',
          gridLineColor: 'rgba(240, 243, 250, 0)',
          fontColor: '#787B86',
          isTransparent: false,
          autosize: false,
          showFloatingTooltip: true,
          showVolume: false,
          scalePosition: 'no',
          scaleMode: 'Normal',
          fontFamily: 'Arial',
          noTimeScale: false,
          chartType: 'area',
          lineColor: 'rgba(255, 0, 0, 1)',
          bottomColor: 'rgba(66, 66, 66, 0)',
          topColor: 'rgba(255, 0, 0, 0.24)',
        }}
      />
      <InvestorRelationsContaact />
    </div>
  )
}

Investors.propTypes = {}
Investors.defaultProps = {}

export default Investors
