import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'
import clsx from 'clsx'

const useStyles = makeStyles({
  polygon: ({ width, angle }) => ({
    width,
    height: 32,
    backgroundColor: colors.primary,
    marginTop: 40,
    clipPath: `polygon(0 0, 100% 0, ${angle} 100%, 0% 100%)`,
  }),
})

const Polygon = ({ className, angle = '85%', width = 100 }) => {
  const classes = useStyles({ width, angle })

  return (
    <div className={clsx(classes.polygon, className)} />
  )
}

Polygon.propTypes = {}
Polygon.defaultProps = {}

export default Polygon
