/**
 * An in-page component to show news articles
 */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import HeaderText from 'components/HeaderText'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { makeStyles } from '@material-ui/core/styles'
import { NEWSROOM } from 'utils/useRoutes'
import SmallNewsCard from './SmallNewsCard'
import { fetchPostsFromRSS } from '../../Pages/Blog/blog.module'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
  },
  content: {
    maxWidth: 1004,
    width: '100%',
    margin: '0 auto',
  },
  postsContainer: {
    padding: '24px 0px',
  },
  gridItem: {
    '&:before': {
      content: '""',
      paddingTop: '100%',
      float: 'left',
    },
  },
}))

const Newsroom = (props) => {
  const classes = useStyles()
  const [newsPosts, setNewsPosts] = useState([])
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    const fetchNewsPosts = async () => {
      const res = await fetchPostsFromRSS()
      setNewsPosts(res.posts || res.data)
    }

    fetchNewsPosts()
  }, [])

  const filteredPosts = newsPosts.slice(0, 3)

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <HeaderText color="secondary" variant="h3">Newsroom</HeaderText>
        <Grid
          container
          direction={matchesSm ? 'row' : 'column'}
          classes={{ container: classes.postsContainer }}
          spacing={4}
        >
          {
            filteredPosts.map((x) => (
              <Grid key={x.link} item xs={12} sm={4} md={12}>
                <SmallNewsCard
                  id={x.link}
                  title={x.title}
                  link={x.link}
                  createdAt={x.createdAt}
                />
              </Grid>
            ))
          }
        </Grid>
        <Link to={NEWSROOM.link}>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            endIcon={<ArrowForwardIcon />}
          >
            Read more news
          </Button>
        </Link>
      </div>
    </div>
  )
}

Newsroom.propTypes = {}
Newsroom.defaultProps = {}

export default Newsroom
