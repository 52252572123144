import { createMuiTheme } from '@material-ui/core/styles'
import { colors } from './theme'

export default createMuiTheme({
  typography: {
    fontFamily: [
      'Arial Rounded MT',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      fontFamily: 'Womby',
      textTransform: 'none',
      fontWeight: 400,
    },
    h1: {
      fontFamily: 'Womby',
      fontWeight: 400,
      fontSize: 122,
      '@media (max-width: 768px)': {
        fontSize: 72,
      },
    },
    h2: {
      fontFamily: 'Womby',
      fontWeight: 400,
      fontSize: 72,
      '@media (max-width: 768px)': {
        fontSize: 40,
      },
    },
    h3: {
      fontFamily: 'Womby',
      fontWeight: 400,
      fontSize: 40,
      '@media (max-width: 768px)': {
        fontSize: 28,
      },
    },
    h4: {
      fontFamily: 'Womby',
      fontWeight: 400,
      fontSize: 28,
      '@media (max-width: 768px)': {
        fontSize: 20,
      },
    },
    h5: {
      fontFamily: 'Womby',
      fontSize: 20,
    },
    subtitle1: {
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: 20,
      fontFamily: 'Arial Rounded MT Bold',
    },
    body2: {
      fontSize: 16,
      fontFamily: 'Arial Rounded MT Bold',
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
  },
  shape: {
    borderRadius: 0,
  },
})
