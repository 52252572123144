import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Card from 'components/Card'
import CardHover from 'components/Card/CardHover'
import LazyImage from 'components/LazyImage'
import Typography from 'components/Typography'
import SocialMediaBar from 'components/SocialMediaBar'
import { getPostLink } from 'utils/createPostURL'
import HeaderText from 'components/HeaderText'
import BlogTitle from './BlogTitle'
import BlogDescription from './BlogDescription'
import BlogTagList from './BlogTagList'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  content: {
    marginLeft: 96,
    paddingTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingBottom: 20,
    },
  },
  postDate: {
    opacity: 0.6,
    marginRight: 20,
  },
  image: {
    maxHeight: 400,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 300,
    },
  },
}))

const BlogListItem = (props) => {
  const { post, tabIndex } = props
  const classes = useStyles()
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const matchesDesktop = useMediaQuery('(min-width: 1160px)')

  // const url = getPostLink(post.id, post.title, post.urlPermalink)
  const url = post.link

  return (
    <CardHover disabled={matchesTablet}>
      <Card
        className={classes.card}
        onClick={() => { window.open(url, '_blank') }}
        tabIndex={tabIndex}
      >
        <HeaderText variant="h3" color="secondary">{format(new Date(post.createdAt), 'yyyy-MM-dd')}</HeaderText>
        <div className={classes.content}>
          {/* <BlogTagList tags={post.tags} /> */}
          <Grid
            container
            direction={matchesTablet ? 'column' : 'row'}
            justify="space-between"
            wrap="nowrap"
            spacing={matchesTablet ? 1 : 4}
          >
            <Grid item>
              <BlogTitle
                color="secondary"
                fontSize={matchesTablet ? 'small' : 'large'}
                title={post.title}
              />
              {/* <Typography style={{ opacity: 0.4, color: '#fff', fontSize: 14 }}>
                {format(new Date(post.createdAt), 'MM/dd/yyyy')}
              </Typography> */}
              {/* <BlogDescription description={post.description} /> */}
            </Grid>
            <Grid item>
              <SocialMediaBar
                title={post.title}
                url={url}
                hashtags={[]}
                orientation={matchesTablet ? 'horizontal' : 'vertical'}
                theme="black"
              />
            </Grid>
          </Grid>
        </div>
        {matchesTablet && <Divider />}
      </Card>
    </CardHover>
  )
}

BlogListItem.propTypes = {}
BlogListItem.defaultProps = {}

export default BlogListItem
