import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { TwitchPlayer as ReactTwitchPlayer } from 'react-twitch-embed'

const useStyles = makeStyles({
  sizedWithAspectRatio: {
    aspectRatio: 16 / 9,
  },
  sizedWithPadding: {
    width: '100%',
    paddingBottom: '56.25%',
    position: 'relative',
  },
  sizedWithPaddingPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
})

const TwitchPlayer = (props) => {
  const { channel, interactive = true, ...rest } = props
  const classes = useStyles()
  const supportsAspectRatio = CSS.supports('aspect-ratio', '16 / 9')

  const parents = [
    'localhost',
    'staging.backstageplay.com',
    'www.backstageplay.com',
  ].map((x) => `parent=${x}`).join('&')

  const options = new URLSearchParams({
    controls: interactive,
    muted: true,
    ...rest,
  })

  return (
    <div
      className={clsx(
        { [classes.sizedWithAspectRatio]: supportsAspectRatio },
        { [classes.sizedWithPadding]: !supportsAspectRatio },
      )}
    >
      <iframe
        style={{ border: 'none' }}
        title={channel}
        src={`https://player.twitch.tv/?channel=${channel}&${parents}&${options.toString()}`}
        height="100%"
        width="100%"
      />
    </div>
  )
}

TwitchPlayer.propTypes = {}
TwitchPlayer.defaultProps = {}

export default TwitchPlayer
