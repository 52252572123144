import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme'

import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 20px',
    textAlign: 'center',
  },
  description1: {
    textTransform: 'uppercase',
    marginBottom: 12,
  },
})

const Copyright = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="body2" color="textSecondary" classes={{ root: classes.description1 }}>
        Copyright backstageplay © {new Date().getFullYear()} All Rights Reserved.
      </Typography>
      <Typography variant="body2" color="primary" style={{ fontSize: 12, textTransform: 'uppercase' }}>
        Powered and Designed by <a href="https://www.staccc.com" target="_blank" rel="noopener noreferrer">Staccc</a>
      </Typography>
    </div>
  )
}

export default Copyright
