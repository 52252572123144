import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, styled } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { colors, MAX_WIDTH } from 'theme'
import contentful from 'utils/contentful'
import Polygon from 'components/Polygon'
import Copy from 'components/Copywrite'
import clsx from 'clsx'

const ElevatedTypography = styled(Typography)({ position: 'relative', zIndex: 5 })

const useSelectedMemberStyles = makeStyles((theme) => ({
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 400px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  names: {
    '@media (max-width: 400px)': {
      padding: 20,
    },
  },
  polygon: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    marginTop: 0,
    width: '100%',
    maxWidth: 400,
  },
  image: {
    height: 140,
    width: 140,
    margin: '0px 40px 0px 20px',
    position: 'relative',
    zIndex: 5,
  },
  bio: {
    lineHeight: 1.5,
    fontSize: 20,
    marginTop: 32,
    padding: '0px 80px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 40px',
    },
  },
}))

const SelectedMember = (props) => {
  const classes = useSelectedMemberStyles()
  const { member } = props

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div className={classes.avatarBox}>
          <img className={classes.image} src={member.picture.url} alt={`${member.firstName} ${member.lastName}`} />
          <div className={classes.names}>
            <ElevatedTypography color="secondary" variant="h3">{member.firstName}</ElevatedTypography>
            <ElevatedTypography color="secondary" variant="h3">{member.lastName}</ElevatedTypography>
            <ElevatedTypography color="secondary" variant="h5" style={{ marginTop: 20 }}>{member.positions.join(', ')}</ElevatedTypography>
          </div>
        </div>
        <Polygon className={classes.polygon} />
      </div>
      <Typography className={classes.bio} color="secondary" variant="body2">{member.bio}</Typography>
    </div>
  )
}

const TeamIntro = ({
  header = <Copy id="ex-1" />,
  description = <Copy id="ex-2" />,
}) => (
  <div style={{ padding: '0px 20px' }}>
    <Typography color="secondary" variant="h2" style={{ marginBottom: 40 }}>
      {header}
    </Typography>
    <Typography color="secondary" variant="body2" style={{ margin: '0px 32px 32px 0px', lineHeight: 1.5 }}>
      {description}
    </Typography>
    <Polygon />
  </div>
)

const useTeamListStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px',
    },
  },
  polygon: {
    marginTop: -16,
    position: 'relative',
    zIndex: 0,
    transform: 'rotate(-180deg)',
    width: '100%',
  },
  nameContainer: {
    padding: '0px 24px',
    width: '100%',
  },
  unselected: {
    transition: 'margin-left 100ms, opacity 100ms',
    opacity: 0.3,
    marginLeft: 64,
    '&:hover': {
      opacity: 1,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  selected: {
    opacity: 1,
    marginLeft: 0,
  },
  clickable: {
    cursor: 'pointer',
  },
}))

const TeamList = (props) => {
  const classes = useTeamListStyles()
  const { members, selectedId, onSelect } = props

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {
          members.map((x) => (
            <Grid
              item
              key={x.id}
              onClick={() => { onSelect(x.id) }}
              classes={{
                item: clsx(
                  classes.unselected,
                  { [classes.selected]: x.id === selectedId },
                  classes.clickable,
                ),
              }}
              xs={12}
            >
              <Grid container alignItems="center" justif="space-between" classes={{ container: classes.nameContainer }}>
                <Grid item style={{ flex: 1 }}>
                  <ElevatedTypography variant="h3" color="secondary">{x.firstName}</ElevatedTypography>
                  <ElevatedTypography variant="h3" color="secondary">{x.lastName}</ElevatedTypography>
                </Grid>
                <Grid item>
                  {
                    x.positions.map((p) => (
                      <ElevatedTypography key={`${x.lastName}-${p}`} variant="h3" color="secondary" style={{ fontSize: 18, margin: '10px 0px' }}>{p}</ElevatedTypography>
                    ))
                  }
                </Grid>
              </Grid>
              <Polygon angle="98%" className={classes.polygon} />
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px 0px',
    maxWidth: 1200,
    margin: '0px auto',
  },
}))

const Team = (props) => {
  const { contentType = 'teamOrder', introHeader, introDescription } = props
  const classes = useStyles()
  const [members, setMembers] = useState([])
  const [selectedId, setSelectedId] = useState(null)

  const getTeamMembers = async () => {
    try {
      const res = await contentful.getEntries({
        content_type: contentType,
      })

      const orderedList = res.items[0].fields.order
      setMembers(orderedList.map((x) => ({ ...x.fields, picture: x.fields.picture.fields.file, id: x.sys.id })))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getTeamMembers()
  }, [])

  const onSelect = (id) => {
    setSelectedId(id)

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const selectedMember = members.find((x) => x.id === selectedId)

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={7}>
          {
            selectedMember ? <SelectedMember member={selectedMember} /> : <TeamIntro header={introHeader} description={introDescription} />
          }
        </Grid>
        <Grid item xs={12} md={5}>
          <TeamList
            members={members}
            selectedId={selectedId}
            onSelect={onSelect}
          />
        </Grid>
      </Grid>
    </div>
  )
}

Team.propTypes = {}
Team.defaultProps = {}

export default Team
