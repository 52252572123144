import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import InputBase from '@material-ui/core/InputBase'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    marginBottom: 8,
    position: 'relative',
    padding: '20px 0px',
    '&:after': {
      width: '100%',
      height: '100%',
      content: '""',
      backgroundColor: theme.palette.primary.main,
      clipPath: 'polygon(50px 0, 100% 0, 100% 100%, 0 100%)',
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  emailField: ({ large }) => ({
    zIndex: 1,
    position: 'relative',
    fontSize: large ? 16 : 12,
    minHeight: 58,
    border: '1px solid #00181c',
    fontFamily: 'Womby',
    margin: '0px 10px 0px 65px',
    padding: '0px 16px',
  }),
}))

const FORM_ACTION_URL = ''

const SubscriptionTextField = (props) => {
  const { large } = props
  const classes = useStyles({ large })
  const [email, setEmail] = useState('')
  const [helperText, setHelperText] = useState('')
  const [subStatus, setSubStatus] = useState(null)

  useEffect(() => {
    if (subStatus === 'success') {
      const id = setTimeout(() => {
        setSubStatus(null)
        setEmail('')
        clearTimeout(id)
      }, 3500)
    }
  }, [subStatus])

  const validateEmail = () => {
    const regex = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/) // eslint-disable-line
    if (!regex.test(email)) {
      setHelperText('Please enter a valid email')

      const id = setTimeout(() => {
        setHelperText('')

        clearTimeout(id)
      }, 3500)
    }
  }

  const handleSubscriptionClick = (subscribe) => {
    setHelperText('')
    validateEmail(email)
    if (helperText) return

    try {
      subscribe({ EMAIL: email })
    } catch (err) {
      console.log(err)
    }
  }

  const onChange = (e) => {
    setEmail(e.target.value)
    setHelperText('')
  }

  const parseMessage = (status, m) => {
    if (m?.includes('already subscribed')) {
      return 'You are already subscribed!'
    }

    if (status === 'error') return 'Please try again later.'

    return m
  }

  if (subStatus === 'success') return <Typography color="secondary">Thank you for subscribing!</Typography>

  return (
    <MailchimpSubscribe
      url={FORM_ACTION_URL}
      render={
        ({ subscribe, status, message }) => {
          if (status === 'success') setSubStatus(status)

          return (
            <div>
              <Paper className={classes.root}>
                <InputBase
                  fullWidth
                  placeholder="Email"
                  onChange={onChange}
                  value={email}
                  classes={{ root: classes.emailField }}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton onClick={() => { handleSubscriptionClick(subscribe) }}>
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </Paper>
              <Typography color="secondary">{helperText || parseMessage(status, message)}</Typography>
            </div>
          )
        }
      }
    />
  )
}

SubscriptionTextField.propTypes = {}
SubscriptionTextField.defaultProps = {}

export default SubscriptionTextField
